import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { ISurveyApi } from '../../../api/SurveyApi';
import { ISurvey } from '../../../domain/surveys/survey/Survey';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import CustomDomainCreateModalHtml from './CustomDomainCreateModal.html';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { CustomDomainItem } from '../../../domain/settings/CustomDomainItem';
import * as angular from 'angular';

export class CustomDomainCreateModal extends Modal {
    static $inject: Array<string> = [
        'itsSurveyApi',
        'iteLogger',
        '$translate',
        '$mdDialog',
        'iteToaster',
        '$window',
        '$scope',
        '$q',
    ];

    protected pager: IPager = new Pager();

    public createAnother: boolean;
    public submitting: boolean = false;
    public editing: boolean;
    public selectedSurvey: any;
    public selectedSurveyOriginalUuid: string;
    public domain: CustomDomainItem;
    public domainName: string;

    private windowWatcher: any = null;

    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = CustomDomainCreateModalHtml;
        config.controller = CustomDomainCreateModal;

        return super.instantiate(config);
    }

    public constructor(
        private api: ISurveyApi,
        private logger: ILogger,
        private translate: ng.translate.ITranslateService,
        private dialog: ng.material.IDialogService,
        private toaster: IToaster,
        private windowService: ng.IWindowService,
        private scope: ng.IScope,
        private q: ng.IQService
    ) {
        super();
        if (this.editing === null) {
            this.editing = false;
        }

        if (this.domain) {
            this.domainName = this.domain.domainName;
            if (this.domain.surveyUuid) {
                this.selectedSurveyOriginalUuid = this.domain.surveyUuid;
                this.selectedSurvey = {
                    originalUuid: this.selectedSurveyOriginalUuid,
                    name: this.domain.name,
                };
            }
        }

        const reloadOnFocus: () => void = () => {
            if (this.selectedSurvey) {
                this.handleSurveySelect(this.selectedSurvey);
            }
        };

        this.windowWatcher = angular.element(this.windowService);
        if (this.windowWatcher) {
            this.windowWatcher.on('focus', reloadOnFocus);

            scope.$on('$destroy', () => {
                this.windowWatcher.off('focus', reloadOnFocus);
            });
        }
    }

    /**
     * Allow a user to search for a survey to distribute
     *
     * @param {string} search
     * @param {IPager} pager
     * @returns {angular.IPromise<any>}
     */
    public searchSurveys(search: string, pager: IPager): ng.IPromise<any> {
        const filters: string = 'filter[active]=true&filter[status]=published';

        return this.api.findSurveys(pager, search, filters);
    }

    /**
     * Display a survey in a string format
     *
     * @param {ISurvey} survey
     * @returns {string}
     */
    public displaySurvey(survey: ISurvey): string {
        return survey ? survey.name : '';
    }

    /**
     * The on-change handler for the survey selection
     * @param {ISurvey} survey
     */
    public handleSurveySelect(survey?: ISurvey): void {
        // clear out the current selections
        if (survey) {
            this.selectedSurvey = survey;
            this.selectedSurveyOriginalUuid = survey.uuid;
        } else {
            this.selectedSurvey = null;
            this.selectedSurveyOriginalUuid = null;
        }
    }

    public submit(form: ng.IFormController): void {
        this.editing ? this.edit(form) : this.create(form);
    }

    public create(form: ng.IFormController): void {
        this.logger.info('Creating custom domain: ' + this.domainName);
        this.submitting = true;
        this.api
            .createCustomDomain(this.domainName, this.selectedSurveyOriginalUuid)
            .then(() => {
                this.logger.info('Created custom domain: ' + this.domainName);
                form.$setPristine();
                form.$setUntouched();
                this.dialog.hide({ domain: this.domainName, createAnother: this.createAnother });
            })
            .catch((error: IErrorResponse) => {
                this.toaster.error(
                    this.translate.instant('ERRORS.SETTINGS.FAILED_CUSTOM_DOMAIN_CREATE'),
                    'Failed to create custom field with name: ' + this.domainName + ' ' + error.message
                );
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    public edit(form: ng.IFormController): void {
        this.logger.info('Editing custom domain: ' + this.domainName);
        this.submitting = true;
        this.api
            .updateCustomDomain(this.domain.uuid, this.selectedSurveyOriginalUuid)
            .then((response) => {
                this.logger.info('Updated custom domain: ' + this.domainName);
                form.$setPristine();
                form.$setUntouched();
                this.dialog.hide(this.domainName);
            })
            .catch((error: IErrorResponse) => {
                this.toaster.error(
                    this.translate.instant('ERRORS.SETTINGS.FAILED_CUSTOM_DOMAIN_UPDATE'),
                    'Failed to update custom field with name: ' + this.domainName + ' ' + error.message
                );
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    /**
     * Cancels form creation and closes dialog
     */
    public cancel(): void {
        this.dialog.cancel();
    }

    public initSelectedSurvey(value: any): ng.IPromise<any> {
        const defer: ng.IDeferred<any> = this.q.defer();
        defer.resolve(value);

        return defer.promise;
    }
}

import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';

export interface ICustomDomainItem extends IEntity {
    uuid: string;
    domainName: string;
    name: string; // the survey's name
    surveyUuid: string;
    createdAt: string;
    updatedAt: string;
}

export class CustomDomainItem extends Entity implements ICustomDomainItem {
    uuid: string = null;
    domainName: string = null;
    name: string = null;
    surveyUuid: string = null;
    createdAt: string = null;
    updatedAt: string = null;
}

import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { HttpClient, IHttpClient } from '@intouch/its.essential/app/essential/http/HttpClient';
import { IConfigurable } from '@intouch/its.essential/app/essential/services/IConfigurable';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { SurveyCollectionItem, ISurveyCollectionItem } from '../domain/surveys/SurveyCollectionItem';
import { Survey, ISurvey } from '../domain/surveys/survey/Survey';
import { Contact, IContact } from '../domain/contact-center/Contact';
import { CustomField, ICustomField } from '../domain/settings/CustomField';
import { ContactList, IContactList } from '../domain/contact-center/ContactList';
import { GeneralSettings, IGeneralSettings } from '../domain/settings/GeneralSettings';
import { EmailDistribution, IEmailDistribution } from '../domain/contact-center/EmailDistribution';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ISmsDistribution, SmsDistribution } from '../domain/contact-center/SmsDistribution';
import {
    IScheduledEmailDistribution,
    ScheduledEmailDistribution,
} from '../domain/contact-center/ScheduledEmailDistribution';
import { IDistributeSurveyRequest } from '../domain/contact-center/DistributeSurveyRequest';
import {
    IScheduledSmsDistribution,
    ScheduledSmsDistribution,
} from '../domain/contact-center/ScheduledSmsDistributions';
import { IQueryFilter, QueryFilter } from '@intouch/its.essential/app/essential/domain/api/QueryFilter';
import { ILocationTag, LocationTag } from '../domain/surveys/Location/LocationTag';
import { ILocationNode } from '../domain/surveys/Location/LocationNode';
import { CustomDomainItem, ICustomDomainItem } from '../domain/settings/CustomDomainItem';

interface IApiClientConfiguration {
    endpoint?: string;
    version?: string;
    trace?: boolean;
}

export interface ISurveyApi extends IHttpClient {
    findSurveys(pager?: IPager, searchTerm?: string, filters?: string): ng.IPromise<PagedEntities>;

    findSurveyByUuid(uuid: string, queryFilter?: IQueryFilter): ng.IPromise<ISurvey>;

    createSurvey(survey: ISurvey): ng.IPromise<ISurvey>;

    createSurveyFromTemplate(name: string, templateUuid: string): ng.IPromise<ISurvey>;

    publishSurvey(uuid: string): ng.IPromise<ISurvey>;

    enableSurvey(uuid: string): ng.IPromise<ISurvey>;

    disableSurvey(uuid: string): ng.IPromise<ISurvey>;

    updateSurvey(survey: ISurvey): ng.IPromise<ISurvey>;

    deleteSurvey(uuid: string, type?: string): ng.IPromise<void>;

    copySurvey(uuid: string, survey: ISurvey): ng.IPromise<ISurvey>;

    getSurveyEmails(originalUuid: string, page?: IPager, searchTerm?: string): ng.IPromise<PagedEntities>;

    getSurveySmsList(originalUuid: string, page?: IPager, searchTerm?: string): ng.IPromise<PagedEntities>;

    getScheduledDistributionsList(
        originalUuid: string,
        sent: boolean,
        type: string,
        pager?: IPager
    ): ng.IPromise<PagedEntities>;

    saveSettings(settings: IGeneralSettings): ng.IPromise<any>;

    getSettings(): ng.IPromise<IGeneralSettings>;

    getCustomDomains(page?: IPager, searchTerm?: string): ng.IPromise<PagedEntities>;

    createCustomDomain(domainName: string, surveyUuid?: string): ng.IPromise<any>;

    updateCustomDomain(customDomainUuid: string, surveyUuid?: string): ng.IPromise<any>;

    deleteCustomDomains(uuid: string): ng.IPromise<any>;

    addPhoto(photo: any): ng.IPromise<any>;

    findSurveyEmail(originalUuid: string, emailUuid: string): ng.IPromise<IEmailDistribution>;

    getSurveySms(originalUuid: string, smsUuid: string): ng.IPromise<ISmsDistribution>;

    createSurveyEmail(originalUuid: string, email: any): ng.IPromise<any>;

    createSurveySms(originalUuid: string, sms: ISmsDistribution): ng.IPromise<any>;

    updateSurveyEmail(originalUuid: string, email: any): ng.IPromise<any>;

    updateSurveySms(originalUuid: string, sms: any): ng.IPromise<any>;

    deleteSurveyEmail(originalUuid: string, emailUuid: string): ng.IPromise<any>;

    deleteSurveySms(originalUuid: string, smsUuid: string): ng.IPromise<any>;

    deleteScheduledDistribution(originalUuid: string, scheduledDistributionUuid: string): ng.IPromise<any>;

    sendTestSurveyEmail(originalUuid: string, email: IEmailDistribution): ng.IPromise<any>;

    sendTestSurveySms(originalUuid: string, message: string, toPhone: string): ng.IPromise<any>;

    enableEmailDistribution(originalUuid: string, email: IEmailDistribution): ng.IPromise<boolean>;

    disableEmailDistribution(originalUuid: string, email: IEmailDistribution): ng.IPromise<boolean>;

    enableSmsDistribution(originalUuid: string, sms: ISmsDistribution): ng.IPromise<boolean>;

    disableSmsDistribution(originalUuid: string, sms: ISmsDistribution): ng.IPromise<boolean>;

    createContact(contact: IContact): ng.IPromise<IContact>;

    getContact(uuid: string): ng.IPromise<IContact>;

    updateContact(contact: IContact): ng.IPromise<IContact>;

    deleteContact(uuid: string): ng.IPromise<any>;

    deleteContacts(contacts: Array<{ uuid: string }>): ng.IPromise<Array<IContact>>;

    findContacts(pager?: IPager, searchTerm?: string, filters?: string): ng.IPromise<PagedEntities>;

    unsubscribeContact(contact: IContact): ng.IPromise<IContact>;

    subscribeContact(contact: IContact): ng.IPromise<IContact>;

    createContactList(contactList: IContactList): ng.IPromise<IContactList>;

    findContactLists(pager?: IPager, searchTerm?: string, filter?: IQueryFilter): ng.IPromise<PagedEntities>;

    getContactList(uuid: string): ng.IPromise<IContactList>;

    updateContactList(contactList: IContactList): ng.IPromise<IContactList>;

    deleteContactList(contactList: IContactList): ng.IPromise<any>;

    exportContacts(status: string, contactLists: Array<string>): ng.IPromise<any>;

    addContactsToList(contactListUuid: string, contacts: Array<string>): ng.IPromise<Array<IContact>>;

    generateContactExportDownload(uuid: string): ng.IPromise<any>;

    findCustomFields(pager?: IPager, searchTerm?: string): ng.IPromise<PagedEntities>;

    findAllCustomFields(): ng.IPromise<PagedEntities>;

    findAllLocationTags(uuid: string): ng.IPromise<ILocationTag[]>;

    findAllLocationNodes(uuid: string): ng.IPromise<ILocationTag[]>;

    createCustomField(customField: ICustomField): ng.IPromise<ICustomField>;

    updateCustomField(customField: ICustomField): ng.IPromise<ICustomField>;

    deleteCustomField(customField: ICustomField): ng.IPromise<void>;

    buildContactCsvUrl(orgUuid: string): string;

    buildImageUploadUrl(): string;

    distributeSurveyToContactList(requestData: IDistributeSurveyRequest): ng.IPromise<any>;

    distributeSurveyToContact(
        contactUuid: string,
        surveyOriginalUuid: string,
        distributionType: string,
        emailOrSmsUuid: string
    ): ng.IPromise<any>;

    buildAddContactsToContactListCsvUrl(): string;
}

/**
 * The survey API wrapper class
 */
@Service('its.survey.api', SurveyApi.IID, SurveyApi)
export class SurveyApi extends HttpClient implements ISurveyApi, IConfigurable {
    static IID: string = 'itsSurveyApi';
    static $inject: Array<string> = ['APPCONFIG', 'iteLogger', '$http', '$q', 'iteAccessService'];

    private endpoint: string;
    private version: string;

    constructor(
        protected config: any,
        protected logger: ILogger,
        protected httpService: ng.IHttpService,
        protected q: ng.IQService,
        protected accessService: IAccessService
    ) {
        super(logger, httpService, q, accessService);
    }

    /**
     * Configure the API client
     *
     * @param {IApiClientConfiguration} data
     */
    public configure(data: IApiClientConfiguration): void {
        this.endpoint = data.endpoint || this.endpoint;
        this.version = data.version || this.version;

        super.configure(data);
    }

    /**
     * Allow a caller to retrieve a collection of paged surveys
     *
     * @param {IPager} pager
     * @param {string} searchTerm
     * @param filter
     * @returns {ng.IPromise<PagedEntities>}
     */
    public findSurveys(pager?: IPager, searchTerm?: string, filter?: string): ng.IPromise<PagedEntities> {
        return this.prepare()
            .get(this.getUrl('/admin/surveys') + this.getPagedParamString(pager, searchTerm, filter))
            .then((response: { data: any }): any => {
                return new PagedEntities(
                    EntityBuilder.buildMany<ISurveyCollectionItem>(SurveyCollectionItem, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    /**
     * Allow a caller to retrieve a survey by its uuid
     *
     * @param {string} uuid
     * @param {IQueryFilter} queryFilter
     * @returns {ng.IPromise<ISurvey>}
     */
    public findSurveyByUuid(uuid: string, queryFilter: IQueryFilter = new QueryFilter()): ng.IPromise<ISurvey> {
        return this.prepare()
            .get(this.getUrl('/admin/surveys/' + uuid), queryFilter.getParams())
            .then((response: { data: any }): any => {
                return EntityBuilder.buildOne<ISurvey>(Survey, response.data, true);
            });
    }

    /**
     * Uploads a photo and returns urls for that photo!
     *
     * @param photo
     * @returns {ng.IPromise<any>}
     */
    public addPhoto(photo: string): ng.IPromise<any> {
        return this.prepare().post(this.getUrl('/admin/media/photos'), {
            data: photo,
            config: {
                small: 480,
                medium: 960,
                large: 1080,
            },
        });
    }

    /**
     * Retrieve list of emails for a survey
     *
     * @param originalUuid
     * @param {IPager} pager
     * @param {string} searchTerm
     * @param {string} filters
     * @returns {angular.IPromise<PagedEntities>}
     */
    public getSurveyEmails(
        originalUuid: string,
        pager?: IPager,
        searchTerm?: string,
        filters?: string
    ): ng.IPromise<PagedEntities> {
        return this.prepare()
            .get(this.getUrl('/admin/surveys/' + originalUuid + '/distributions/emails'), pager.getQueryParamsObject())
            .then((response: { data: any }): any => {
                return new PagedEntities(
                    EntityBuilder.buildMany<IEmailDistribution>(EmailDistribution, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    public getCustomDomains(pager?: IPager, searchTerm?: string): ng.IPromise<PagedEntities> {
        return this.prepare()
            .get(this.getUrl('/admin/custom_domains') + this.getPagedParamString(pager, searchTerm))
            .then((response: { data: any }): any => {
                return new PagedEntities(
                    EntityBuilder.buildMany<ICustomDomainItem>(CustomDomainItem, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    public createCustomDomain(domainName: string, surveyUuid?: string): ng.IPromise<any> {
        return this.prepare().post(this.getUrl('/admin/custom_domains'), {
            domain_name: domainName,
            survey_uuid: surveyUuid,
        });
    }

    public updateCustomDomain(customDomainUuid: string, surveyUuid?: string): ng.IPromise<any> {
        return this.prepare().put(this.getUrl('/admin/custom_domains/' + customDomainUuid), {
            survey_uuid: surveyUuid,
        });
    }

    public deleteCustomDomains(uuid: string): ng.IPromise<any> {
        return this.prepare().del(this.getUrl('/admin/custom_domains/' + uuid));
    }

    /**
     * Retrieve list of sms for a survey
     *
     * @param originalUuid
     * @param {IPager} pager
     * @param {string} searchTerm
     * @param {string} filters
     * @returns {angular.IPromise<PagedEntities>}
     */
    public getSurveySmsList(
        originalUuid: string,
        pager?: IPager,
        searchTerm?: string,
        filters?: string
    ): ng.IPromise<PagedEntities> {
        return this.prepare()
            .get(this.getUrl('/admin/surveys/' + originalUuid + '/distributions/sms'), pager.getQueryParamsObject())
            .then((response: { data: any }): any => {
                return new PagedEntities(
                    EntityBuilder.buildMany<ISmsDistribution>(SmsDistribution, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    /**
     * Retrieve a list of scheduled distributions
     *
     * @param originalUuid
     * @param sent
     * @param type
     * @param pager
     */
    public getScheduledDistributionsList(
        originalUuid: string,
        sent: boolean,
        type: string,
        pager?: IPager
    ): ng.IPromise<PagedEntities> {
        return this.prepare()
            .get(
                this.getUrl(
                    '/admin/surveys/' + originalUuid + '/distributions/scheduled?sent=' + sent + '&type=' + type
                ),
                pager.getQueryParamsObject()
            )
            .then((response: { data: any }): any => {
                if (type === 'email') {
                    return new PagedEntities(
                        EntityBuilder.buildMany<IScheduledEmailDistribution>(
                            ScheduledEmailDistribution,
                            response.data.data,
                            true
                        ),
                        EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                    );
                } else {
                    // must be (type === 'sms')
                    return new PagedEntities(
                        EntityBuilder.buildMany<IScheduledSmsDistribution>(
                            ScheduledSmsDistribution,
                            response.data.data,
                            true
                        ),
                        EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                    );
                }
            });
    }

    /**
     * Find an existing survey email
     *
     * @param {string} originalUuid
     * @param {string} emailUuid
     * @returns {angular.IPromise<IEmailDistribution>}
     */
    public findSurveyEmail(originalUuid: string, emailUuid: string): ng.IPromise<IEmailDistribution> {
        return this.prepare()
            .get(this.getUrl('/admin/surveys/' + originalUuid + '/distributions/emails/' + emailUuid))
            .then((response) => {
                return EntityBuilder.buildOne<IEmailDistribution>(EmailDistribution, response.data, true);
            });
    }

    /**
     * Fetch an existing survey SMS
     *
     * @param {string} originalUuid
     * @param {string} smsUuid
     *
     * @returns {angular.IPromise<ISmsDistribution>}
     */
    public getSurveySms(originalUuid: string, smsUuid: string): ng.IPromise<ISmsDistribution> {
        return this.prepare()
            .get(this.getUrl('/admin/surveys/' + originalUuid + '/distributions/sms/' + smsUuid))
            .then((response) => {
                return EntityBuilder.buildOne<ISmsDistribution>(SmsDistribution, response.data, true);
            });
    }

    /**
     * Creates a survey email template
     *
     * @param {string} originalUuid
     * @param email
     * @returns {angular.IPromise<any>}
     */
    public createSurveyEmail(originalUuid: string, email: any): ng.IPromise<any> {
        return this.prepare().post(
            this.getUrl('/admin/surveys/' + originalUuid + '/distributions/emails'),
            email.toJson(true)
        );
    }

    /**
     * Creates a survey sms template
     *
     * @param {string} originalUuid
     * @param {ISmsDistribution} sms
     * @returns {angular.IPromise<any>}
     */
    public createSurveySms(originalUuid: string, sms: ISmsDistribution): ng.IPromise<any> {
        return this.prepare().post(
            this.getUrl('/admin/surveys/' + originalUuid + '/distributions/sms'),
            sms.toJson(true)
        );
    }

    /**
     * Update a survey email template
     *
     * @param {string} originalUuid
     * @param email
     * @returns {angular.IPromise<any>}
     */
    public updateSurveyEmail(originalUuid: string, email: any): ng.IPromise<any> {
        return this.prepare().put(
            this.getUrl('/admin/surveys/' + originalUuid + '/distributions/emails/' + email.uuid),
            email.toJson(true)
        );
    }

    /**
     * Update a survey sms template
     *
     * @param {string} originalUuid
     * @param sms
     * @returns {angular.IPromise<any>}
     */
    public updateSurveySms(originalUuid: string, sms: any): ng.IPromise<any> {
        return this.prepare().put(
            this.getUrl('/admin/surveys/' + originalUuid + '/distributions/sms/' + sms.uuid),
            sms.toJson(true)
        );
    }

    /**
     * Deletes a survey email template
     *
     * @param {string} originalUuid
     * @param {string} emailUuid
     * @returns {angular.IPromise<any>}
     */
    public deleteSurveyEmail(originalUuid: string, emailUuid: string): ng.IPromise<any> {
        return this.prepare().del(this.getUrl('/admin/surveys/' + originalUuid + '/distributions/emails/' + emailUuid));
    }

    /**
     * Deletes a survey SMS template
     *
     * @param {string} originalUuid
     * @param {string} smsUuid
     * @returns {angular.IPromise<any>}
     */
    public deleteSurveySms(originalUuid: string, smsUuid: string): ng.IPromise<any> {
        return this.prepare().del(this.getUrl('/admin/surveys/' + originalUuid + '/distributions/sms/' + smsUuid));
    }

    /**
     * Delete a Scheduled Distribution
     *
     * @param originalUuid
     * @param scheduledDistributionUuid
     */
    public deleteScheduledDistribution(originalUuid: string, scheduledDistributionUuid: string): ng.IPromise<any> {
        return this.prepare().del(
            this.getUrl('/admin/surveys/' + originalUuid + '/distributions/scheduled/' + scheduledDistributionUuid)
        );
    }

    /**
     * Sends a test survey email.
     *
     * @param {string} originalUuid
     * @param {IEmailDistribution} email
     * @returns {angular.IPromise<any>}
     */
    public sendTestSurveyEmail(originalUuid: string, email: IEmailDistribution): ng.IPromise<any> {
        return this.prepare().post(this.getUrl('/admin/surveys/' + originalUuid + '/send_test'), email.toJson(true));
    }

    /**
     * Sends a test SMS
     *
     * @param {string} originalUuid
     * @param {string} message
     * @param {string} toPhone
     *
     * @returns {angular.IPromise<any>}
     */
    public sendTestSurveySms(originalUuid: string, message: string, toPhone: string): ng.IPromise<any> {
        return this.prepare().post(this.getUrl('/admin/surveys/' + originalUuid + '/distributions/sms/send_test'), {
            phone: toPhone,
            message: message,
        });
    }

    /**
     * Enable email distribution
     *
     * @param {string} originalUuid
     * @param {IEmailDistribution} email
     * @returns {angular.IPromise<boolean>}
     */
    public enableEmailDistribution(originalUuid: string, email: IEmailDistribution): ng.IPromise<boolean> {
        return this.prepare().put(
            this.getUrl('/admin/surveys/' + originalUuid + '/distributions/emails/' + email.uuid + '/enable')
        );
    }

    /**
     * Disable email distribution
     *
     * @param {string} originalUuid
     * @param {IEmailDistribution} email
     * @returns {angular.IPromise<boolean>}
     */
    public disableEmailDistribution(originalUuid: string, email: IEmailDistribution): ng.IPromise<boolean> {
        return this.prepare().put(
            this.getUrl('/admin/surveys/' + originalUuid + '/distributions/emails/' + email.uuid + '/disable')
        );
    }

    /**
     * Enable sms distribution
     *
     * @param {string} originalUuid
     * @param {ISmsDistribution} sms
     * @returns {angular.IPromise<boolean>}
     */
    public enableSmsDistribution(originalUuid: string, sms: ISmsDistribution): ng.IPromise<boolean> {
        return this.prepare().put(
            this.getUrl('/admin/surveys/' + originalUuid + '/distributions/sms/' + sms.uuid + '/enable')
        );
    }

    /**
     * Disable sms distribution
     *
     * @param {string} originalUuid
     * @param {ISmsDistribution} sms
     * @returns {angular.IPromise<boolean>}
     */
    public disableSmsDistribution(originalUuid: string, sms: ISmsDistribution): ng.IPromise<boolean> {
        return this.prepare().put(
            this.getUrl('/admin/surveys/' + originalUuid + '/distributions/sms/' + sms.uuid + '/disable')
        );
    }

    /**
     * Creates a survey and returns the created entity
     *
     * @param survey
     * @returns {IPromise<ISurvey>}
     */
    public createSurvey(survey: ISurvey): ng.IPromise<ISurvey> {
        return this.prepare()
            .post(this.getUrl('/admin/surveys/'), survey.toJson(true))
            .then((response) => {
                return EntityBuilder.buildOne<ISurvey>(Survey, response.data, true);
            });
    }

    /**
     * Creates a survey from a given template uuid and returns the created entity
     *
     * @param name
     * @param templateUuid
     * @returns {IPromise<ISurvey>}
     */
    public createSurveyFromTemplate(name: string, templateUuid: string): ng.IPromise<ISurvey> {
        return this.prepare()
            .post(this.getUrl('/admin/surveys/install/' + templateUuid), { name: name })
            .then((response) => {
                return EntityBuilder.buildOne<ISurvey>(Survey, response.data, true);
            });
    }

    /**
     * Publish survey via UUID and return new survey entity
     *
     * @param {string} uuid
     * @returns {ng.IPromise<ISurvey>}
     */
    public publishSurvey(uuid: string): ng.IPromise<ISurvey> {
        return this.prepare()
            .put(this.getUrl('/admin/surveys/' + uuid + '/publish'))
            .then((response) => {
                return EntityBuilder.buildOne<ISurvey>(Survey, response.data, true);
            });
    }

    /**
     * Enable (make active) a survey and return the updated survey entity
     *
     * @param {string} uuid
     * @returns {ng.IPromise<ISurvey>}
     */
    public enableSurvey(uuid: string): ng.IPromise<ISurvey> {
        return this.prepare()
            .put(this.getUrl('/admin/surveys/' + uuid + '/enable'))
            .then((response) => {
                return EntityBuilder.buildOne<ISurvey>(Survey, response.data, true);
            });
    }

    /**
     * Disable (make inactive) a survey and return the updated survey entity
     *
     * @param {string} uuid
     * @returns {ng.IPromise<ISurvey>}
     */
    public disableSurvey(uuid: string): ng.IPromise<ISurvey> {
        return this.prepare()
            .put(this.getUrl('/admin/surveys/' + uuid + '/disable'))
            .then((response) => {
                return EntityBuilder.buildOne<ISurvey>(Survey, response.data, true);
            });
    }

    /**
     * Updates a survey and returns the new entity
     *
     * @param survey
     * @returns {angular.IPromise<ISurvey>}
     */
    public updateSurvey(survey: ISurvey): ng.IPromise<ISurvey> {
        return this.prepare()
            .put(this.getUrl('/admin/surveys/') + survey.uuid, survey.toJson(true))
            .then((response) => {
                return EntityBuilder.buildOne<ISurvey>(Survey, response.data, true);
            });
    }

    /**
     * Deletes the survey
     *
     * @param {string} uuid
     * @param {string} type
     * @returns {ng.IPromise<any>}
     */
    public deleteSurvey(uuid: string, type: string = 'revision'): ng.IPromise<any> {
        return this.prepare().del(this.getUrl('/admin/surveys/' + uuid + '?type=' + type));
    }

    /**
     * Copy a survey
     *
     * @param {string} uuid
     * @param {ISurvey} survey
     * @returns {angular.IPromise<ISurvey>}
     */
    public copySurvey(uuid: string, survey: ISurvey): ng.IPromise<ISurvey> {
        return this.prepare()
            .post(this.getUrl('/admin/surveys/' + uuid + '/copy'), survey.toJson(true))
            .then((response) => {
                return EntityBuilder.buildOne<ISurvey>(Survey, response.data, true);
            });
    }

    /**
     * Save the general settings
     *
     * @param {IGeneralSettings} settings
     * @returns {angular.IPromise<any>}
     */
    public saveSettings(settings: IGeneralSettings): ng.IPromise<IGeneralSettings> {
        return this.prepare().put(this.getUrl('/admin/settings'), settings.toJson(true));
    }

    /**
     * Get saved settings
     *
     * @returns {angular.IPromise<IGeneralSettings>}
     */
    public getSettings(): ng.IPromise<IGeneralSettings> {
        return this.prepare()
            .get(this.getUrl('/admin/settings'))
            .then((response) => {
                return EntityBuilder.buildOne<IGeneralSettings>(GeneralSettings, response.data, true);
            });
    }

    /**
     * Creates a contact and returns the created entity
     *
     * @param contact
     * @returns {IPromise<ISurvey>}
     */
    public createContact(contact: IContact): ng.IPromise<IContact> {
        return this.prepare()
            .post(this.getUrl('/admin/contacts'), contact.toJson(true))
            .then((response) => {
                return EntityBuilder.buildOne<IContact>(Contact, response.data, true);
            });
    }

    /**
     * Get a contact
     *
     * @param uuid
     */
    public getContact(uuid: string): ng.IPromise<IContact> {
        return this.prepare()
            .get(this.getUrl('/admin/contacts/' + uuid))
            .then((response) => {
                return EntityBuilder.buildOne<IContact>(Contact, response.data, true);
            });
    }

    /**
     * Updates a contact and returns the new entity
     *
     * @param contact
     * @returns {angular.IPromise<ISurvey>}
     */
    public updateContact(contact: IContact): ng.IPromise<IContact> {
        return this.prepare()
            .put(this.getUrl('/admin/contacts/') + contact.uuid, contact.toJson(true))
            .then((response) => {
                return EntityBuilder.buildOne<IContact>(Contact, response.data, true);
            });
    }

    /**
     * Updates a contact status to unsubscribed
     *
     * @param contact
     * @returns {angular.IPromise<ISurvey>}
     */
    public unsubscribeContact(contact: IContact): ng.IPromise<IContact> {
        return this.prepare()
            .put(this.getUrl('/admin/contacts/') + contact.uuid + '/unsubscribe')
            .then((response) => {
                return EntityBuilder.buildOne<IContact>(Contact, response.data, true);
            });
    }

    /**
     * Updates a contact status to subscribed
     *
     * @param contact
     * @returns {angular.IPromise<ISurvey>}
     */
    public subscribeContact(contact: IContact): ng.IPromise<IContact> {
        return this.prepare()
            .put(this.getUrl('/admin/contacts/') + contact.uuid + '/subscribe')
            .then((response) => {
                return EntityBuilder.buildOne<IContact>(Contact, response.data, true);
            });
    }

    /**
     * Deletes the contact
     *
     * @param {string} uuid
     * @returns {ng.IPromise<any>}
     */
    public deleteContact(uuid: string): ng.IPromise<any> {
        return this.prepare().del(this.getUrl('/admin/contacts/' + uuid));
    }

    /**
     * Delete many contacts, returns an array of 'failures' containing contact UUIDs
     *
     * @returns {ng.IPromise<Array<any>>}
     */
    public deleteContacts(contacts: Array<{ uuid: string }>): ng.IPromise<Array<any>> {
        return this.prepare()
            .del(
                this.getUrl('/admin/contacts'),
                { data: contacts },
                { headers: { 'Content-Type': 'application/json;charset=utf-8' } }
            ) // angular doesnt send this on del's. Need to include it here
            .then((response: { data: any }): any => {
                return EntityBuilder.buildMany<IContact>(Contact, response.data.failures, true);
            });
    }

    /**
     * Allow a caller to retrieve a collection of paged contacts
     *
     * @param {IPager} pager
     * @param {string} searchTerm
     * @param filters
     * @returns {ng.IPromise<PagedEntities>}
     */
    public findContacts(pager?: IPager, searchTerm?: string, filters?: string): ng.IPromise<PagedEntities> {
        return this.prepare()
            .get(this.getUrl('/admin/contacts') + this.getPagedParamString(pager, searchTerm, filters))
            .then((response: { data: any }): any => {
                return new PagedEntities(
                    EntityBuilder.buildMany<IContact>(Contact, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    /**
     * Allow a caller to retrieve a collection of paged contact lists
     *
     * @param {IPager} pager
     * @param {string} searchTerm
     * @param filter
     * @returns {ng.IPromise<PagedEntities>}
     */
    public findContactLists(
        pager?: IPager,
        searchTerm?: string,
        filter: IQueryFilter = new QueryFilter()
    ): ng.IPromise<PagedEntities> {
        if (pager) {
            filter.addPager(pager);
        }
        if (searchTerm) {
            filter.addSearch(searchTerm);
        }
        return this.prepare()
            .get(this.getUrl('/admin/contacts/contact_lists'), filter.getParams())
            .then((response: { data: any }): any => {
                return new PagedEntities(
                    EntityBuilder.buildMany<IContactList>(ContactList, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    /**
     * Gets a contact list by uuid
     *
     * @param {string} uuid
     * @returns {angular.IPromise<IContactList>}
     */
    public getContactList(uuid: string): ng.IPromise<IContactList> {
        return this.prepare()
            .get(this.getUrl('/admin/contacts/contact_lists/') + uuid)
            .then((response: { data: IContactList }) => {
                return EntityBuilder.buildOne<IContactList>(ContactList, response.data, true);
            });
    }

    /**
     * Creates a contact list and returns the created entity
     *
     * @param contactList
     * @returns {IPromise<IContactList>}
     */
    public createContactList(contactList: IContactList): ng.IPromise<IContactList> {
        return this.prepare()
            .post(this.getUrl('/admin/contacts/contact_lists'), contactList.toJson(true))
            .then((response) => {
                return EntityBuilder.buildOne<IContactList>(ContactList, response.data, true);
            });
    }

    /**
     * Updates a contact list and returns the new entity
     *
     * @param contactList
     * @returns {angular.IPromise<ISurvey>}
     */
    public updateContactList(contactList: IContactList): ng.IPromise<IContactList> {
        return this.prepare()
            .put(this.getUrl('/admin/contacts/contact_lists/') + contactList.uuid, contactList.toJson(true))
            .then((response) => {
                return EntityBuilder.buildOne<IContactList>(ContactList, response.data, true);
            });
    }

    /**
     * Deletes the contact list
     *
     * @param contactList
     * @returns {ng.IPromise<any>}
     */
    public deleteContactList(contactList: IContactList): ng.IPromise<any> {
        return this.prepare().del(this.getUrl('/admin/contacts/contact_lists/' + contactList.uuid));
    }

    /**
     * Exports the contacts as CSV
     *
     * @param status
     * @param contactLists
     * @returns {ng.IPromise<any>}
     */
    public exportContacts(status: string = null, contactLists: Array<string> = []): ng.IPromise<any> {
        return this.prepare().post(this.getUrl('/admin/contacts/export'), {
            status: status,
            contact_list_uuids: contactLists,
        });
    }

    /**
     * Bulk assigns contacts to a single contact list, returns an array of 'failures' containing contact UUIDs
     *
     * @param contactListUuid
     * @param contacts
     *
     * @returns {ng.IPromise<Array<any>>}
     */
    public addContactsToList(contactListUuid: string, contacts: Array<string>): ng.IPromise<Array<any>> {
        return this.prepare()
            .post(this.getUrl('/admin/contacts/contact_lists/' + contactListUuid + '/add_contacts'), { data: contacts })
            .then((response: { data: any }): any => {
                return EntityBuilder.buildMany<IContact>(Contact, response.data.failures, true);
            });
    }

    /**
     * Generates a signed URL to download a contacts export.
     *
     * @param uuid
     */
    public generateContactExportDownload(uuid: string): ng.IPromise<any> {
        return this.prepare().get(this.getUrl('/admin/exports/' + uuid + '/download'));
    }

    /**
     * Allow a caller to retrieve a collection of paged custom fields
     *
     * @param {IPager} pager
     * @param {string} searchTerm
     * @returns {ng.IPromise<PagedEntities>}
     */
    public findCustomFields(pager?: IPager, searchTerm?: string): ng.IPromise<PagedEntities> {
        return this.prepare()
            .get(this.getUrl('/admin/contacts/custom_fields') + this.getPagedParamString(pager, searchTerm))
            .then((response: { data: any }): any => {
                return new PagedEntities(
                    EntityBuilder.buildMany<ICustomField>(CustomField, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    /**
     * Allow a caller to retrieve all custom fields for an organization
     *
     * @returns {ng.IPromise<PagedEntities>}
     */
    public findAllCustomFields(): ng.IPromise<PagedEntities> {
        return this.prepare()
            .get(this.getUrl('/admin/contacts/custom_fields?all=true'))
            .then((response: { data: any }): any => {
                return new PagedEntities(
                    EntityBuilder.buildMany<ICustomField>(CustomField, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    /**
     * Allow a caller to retrieve all locations for the survey
     *
     * @param uuid
     * @returns {ng.IPromise<PagedEntities>}
     */
    public findAllLocationNodes(uuid: string): ng.IPromise<ILocationNode[]> {
        return this.prepare()
            .get(this.getUrl(`/surveys/${uuid}/locations`))
            .then((response: { data: any }): any => {
                const locationNodes: any = response.data.data;
                const uniqueTagsArray: ILocationTag[] = Array.from(locationNodes.values());
                return uniqueTagsArray;
            });
    }

    /**
     * Allow a caller to retrieve all unique location tags for the survey
     *
     * @param uuid
     * @returns {ng.IPromise<PagedEntities>}
     */
    public findAllLocationTags(uuid: string): ng.IPromise<ILocationTag[]> {
        return this.prepare()
            .get(this.getUrl(`/surveys/${uuid}/locations`))
            .then((response: { data: any }): any => {
                const locations: any = response.data.data;
                const locationTags: ILocationTag[] = locations.flatMap((location) => location.tags);
                const uniqueTagsMap: Map<string, ILocationTag> = new Map<string, ILocationTag>();
                locationTags.forEach((tag) => {
                    if (!uniqueTagsMap.has(tag.uuid)) {
                        uniqueTagsMap.set(tag.uuid, tag);
                    }
                });
                const uniqueTagsArray: ILocationTag[] = Array.from(uniqueTagsMap.values());
                return uniqueTagsArray;
            });
    }

    /**
     * Updates contact fields and returns new listing
     *
     * @param customField
     * @returns {angular.IPromise<ICustomField>}
     */
    public updateCustomField(customField: ICustomField): ng.IPromise<ICustomField> {
        return this.prepare()
            .put(this.getUrl('/admin/contacts/custom_fields/' + customField.uuid), customField.toJson(true))
            .then((response) => {
                return EntityBuilder.buildOne<ICustomField>(CustomField, response.data, true);
            });
    }

    /**
     * Create a custom field
     *
     * @param {ICustomField} customField
     * @returns {angular.IPromise<ICustomField>}
     */
    public createCustomField(customField: ICustomField): ng.IPromise<ICustomField> {
        return this.prepare()
            .post(this.getUrl('/admin/contacts/custom_fields'), customField.toJson(true))
            .then((response) => {
                return EntityBuilder.buildOne<ICustomField>(CustomField, response.data, true);
            });
    }

    /**
     * Delete a custom field
     *
     * @param {ICustomField} customField
     * @returns {angular.IPromise<ICustomField>}
     */
    public deleteCustomField(customField: ICustomField): ng.IPromise<void> {
        return this.prepare().del(this.getUrl('/admin/contacts/custom_fields/' + customField.uuid));
    }

    /**
     * Build the CSV url from the org UUID
     *
     * @param {string} orgUuid
     * @returns {string}
     */
    public buildContactCsvUrl(orgUuid: string): string {
        return this.getUrl('/org/' + orgUuid + '/contacts/upload/template');
    }

    /**
     * Build image upload url
     */
    public buildImageUploadUrl(): string {
        return this.getUrl('/admin/media/photos');
    }

    /**
     * Manually distribute a survey to a contact list
     *
     * @param {IDistributeSurveyRequest} requestData
     *
     * @return {angular.IPromise<any>}
     */
    public distributeSurveyToContactList(requestData: IDistributeSurveyRequest): ng.IPromise<any> {
        const data: any = {
            survey_original_uuid: requestData.surveyOriginalUuid,
            type: requestData.distributionType,
            send_at: requestData.sendAtDateFormatted,
            timezone: requestData.sendAtDateTimezone,
        };
        const uuidKey: string = 'survey_' + requestData.distributionType + '_uuid';
        data[uuidKey] = requestData.emailOrSmsUuid;

        return this.prepare()
            .post(this.getUrl('/admin/contacts/contact_lists/' + requestData.contactListUuid + '/send'), data)
            .then((response) => {
                return response.data;
            });
    }

    /**
     * Manually distribute a survey to a single contact
     *
     * @param contactUuid
     * @param surveyOriginalUuid
     * @param distributionType
     * @param emailOrSmsUuid
     *
     * @return {angular.IPromise<any>}
     */
    public distributeSurveyToContact(
        contactUuid: string,
        surveyOriginalUuid: string,
        distributionType: string,
        emailOrSmsUuid: string
    ): ng.IPromise<any> {
        const input: any = {
            survey_original_uuid: surveyOriginalUuid,
            type: distributionType,
        };
        const uuidKey: string = 'survey_' + distributionType + '_uuid';
        input[uuidKey] = emailOrSmsUuid;

        return this.prepare()
            .post(this.getUrl('/admin/contacts/' + contactUuid + '/distribute_survey'), input)
            .then((response) => {
                return response.data;
            });
    }

    /**
     * Build the CSV template url for adding contacts to a contact list
     *
     * @returns {string}
     */
    public buildAddContactsToContactListCsvUrl(): string {
        return this.endpoint + '/templates/' + 'AddContactsToContactListTemplate.csv';
    }

    /**
     * Retrieve the base URL of our API endpoint
     *
     * @param source
     */
    private getUrl(source: string = ''): string {
        return this.endpoint + '/' + this.version + source;
    }
}

import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { ISurveySession } from '../../../services/SurveySession';
import { CustomDomainCreateModal } from './CustomDomainCreateModal';
import { ISurveyApi } from '../../../api/SurveyApi';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { GeneralSettings, IGeneralSettings } from '../../../domain/settings/GeneralSettings';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { ICustomDomainItem } from '../../../domain/settings/CustomDomainItem';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { DatatableSearch } from '@intouch/its.essential/app/essential/domain/datatable/DatatableSearch';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';

@Controller('its.survey.module.settings', CustomDomainsController.IID, CustomDomainsController)
class CustomDomainsController {
    static IID: string = 'its.survey.module.settings.CustomDomainsController';
    static $inject: Array<string> = [
        '$state',
        '$mdDialog',
        'itsSurveyApi',
        'itsSurveySession',
        'iteAccessService',
        'iteToaster',
        '$translate',
    ];

    public hasSurveyAdmin: boolean;

    protected customDomains: Array<ICustomDomainItem> = [];
    protected pager: IPager = null;
    protected settings: IGeneralSettings = new GeneralSettings();
    protected isIntouch: boolean = false;
    protected loading: boolean = false;
    protected hasError: boolean = false;
    protected datatableSearch: DatatableSearch = new DatatableSearch();

    constructor(
        private stateService: ng.ui.IStateService,
        private dialog: ng.material.IDialogService,
        private surveyApi: ISurveyApi,
        private session: ISurveySession,
        private accessService: IAccessService,
        private toaster: IToaster,
        private translate: ng.translate.ITranslateService
    ) {
        this.isIntouch = this.session.getUser().isIntouchUser();
        this.hasSurveyAdmin = this.session.getToken().getUser().hasAcl('survey_admin');
        this.pager = Pager.make(
            this.stateService.params['page'],
            this.stateService.params['sort_by'],
            this.stateService.params['order']
        );
        this.datatableSearch.term = this.stateService.params['search'] || null;

        this.load();
    }

    /**
     * Opens the create custom domain modal
     * @param {boolean} createAnother
     */
    public create(createAnother: boolean = false): void {
        this.dialog
            .show(
                CustomDomainCreateModal.instantiate({
                    locals: {
                        createAnother: createAnother,
                    },
                })
            )
            .then((result: { domain: string; createAnother: boolean }) => {
                this.toaster.success(
                    this.translate.instant('CONTACT_CENTER.SETTINGS.CREATED_CUSTOM_DOMAIN', { label: result.domain })
                );
                this.load();
                if (result.createAnother) {
                    this.create(true);
                }
            });
    }

    /**
     * Allow a user to search for users by name
     */
    public search(): void {
        this.customDomains = [];
        this.pager.currentPage = 1;
        this.goToSelf(this.pager);
    }

    /**
     * Allow a user to go to the next page of submissions
     */
    public next(): void {
        if (this.pager.canGoNext()) {
            this.pager.currentPage++;
            this.goToSelf(this.pager);
        }
    }

    /**
     * Allow a user to go to the previous page of submissions
     */
    public prev(): void {
        if (this.pager.canGoPrevious()) {
            this.pager.currentPage--;
            this.goToSelf(this.pager);
        }
    }

    /**
     * Reload page with pager information
     *
     * @param {IPager} pager
     */
    public goToSelf(pager: IPager): void {
        this.stateService.go(
            'home.settings.custom-domains',
            {
                search: this.datatableSearch.term,
                page: pager.currentPage,
                sort_by: pager.sortBy,
                order: pager.order,
            },
            {
                notify: false, // prevent the events onStart and onSuccess from firing
                reload: false, // prevent reload of the current state
                location: 'replace', // replace the last record when changing the params so you don't hit the back button and get old params
                inherit: true, // inherit the current params on the url
            }
        );
        this.load();
    }

    /**
     * Allow a user to toggle the sort order of a column
     *
     * @param field
     */
    public toggleSort(field: string): void {
        if (this.pager.isCurrentSort(field)) {
            this.pager.toggleSortOrder();
        }
        this.pager.sortBy = field;
        this.goToSelf(this.pager);
    }

    /**
     * Opens the edit custom domain modal
     * @param domain
     * @param {boolean} createAnother
     */
    public edit(domain: ICustomDomainItem, createAnother: boolean = false): void {
        this.dialog
            .show(
                CustomDomainCreateModal.instantiate({
                    locals: {
                        domain: domain,
                        editing: true,
                    },
                })
            )
            .then((result: string) => {
                this.toaster.info(
                    this.translate.instant('CONTACT_CENTER.SETTINGS.UPDATED_CUSTOM_DOMAIN', { label: result })
                );
                this.load();
            });
    }

    /**
     * Loads custom domain listing page
     */
    public load(): void {
        const token: IToken = this.accessService.getToken();
        this.loading = true;

        if (token && token.getOrganization()) {
            this.surveyApi
                .getCustomDomains(this.pager, this.datatableSearch.term)
                .then((results: PagedEntities) => {
                    this.customDomains = results.getEntities();
                    this.pager = results.getPager();
                    this.hasError = false;
                })
                .catch(() => {
                    this.hasError = true;
                    this.toaster.error('ERRORS.SETTINGS.FAILED_CUSTOM_FIELDS_LOAD');
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    public remove(customDomain: ICustomDomainItem): void {
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        title: this.translate.instant('CONTACT_CENTER.CUSTOM_DOMAIN.DELETE_CD_TITLE'),
                        description: this.translate.instant('CONTACT_CENTER.CUSTOM_DOMAIN.CONFIRM_DELETE_CD'),
                        confirmText: this.translate.instant('GENERAL.DELETE'),
                        cancelText: this.translate.instant('GENERAL.CANCEL'),
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((confirmed) => {
                if (confirmed) {
                    this.surveyApi
                        .deleteCustomDomains(customDomain.uuid)
                        .then(() => {
                            this.toaster.success('CONTACT_CENTER.CUSTOM_DOMAIN.CD_DELETED');
                            this.pager.currentPage = 1;
                            this.load();
                        })
                        .catch(() => {
                            this.toaster.error('ERRORS.FAILED_CD_DELETE');
                        });
                }
            });
    }
}
